<template>
    <div v-show="playerInfo.transferData">
        <div class="player__transfer-table__header">
            <div class="player__transfer-table__header-child">{{$t('DATE')}}</div>
            <div class="player__transfer-table__header-child">{{$t('FROM')}}</div>
            <div class="player__transfer-table__header-child">{{$t('TO')}}</div>
            <div class="player__transfer-table__header-child">{{$t('COST')}}</div>
        </div>
        <div class="player__transfer-table__body"  v-for="(o, index) in playerInfo.transferData" :key="index">
            <div class="player__transfer-table__body-child">{{o.date}}</div>
             <Navigation :id="o.from_id" :category="'team'" :name="o.from"  class="player__transfer-table__body-child flex-col" >
                <Icon  :logoUrl=o.fromLogo class="player__transfer-table__body-img"></Icon> 
                <span>{{$t(o.from)}}</span>
            </Navigation>
            <Navigation :id="o.to_id" :category="'team'" :name="o.to"  class="player__transfer-table__body-child flex-col" >
                <Icon :logoUrl=o.toLogo class="player__transfer-table__body-img"></Icon> 
                <span>{{$t(o.to)}}</span>            
            </Navigation>

            <div  class="player__transfer-table__body-child">{{o.type}}</div>
        </div>
    </div>
    <div class="no-data" v-show="!playerInfo.transferData">{{$t('NO_TRANSFER')}}</div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
export default {
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters([
            'playerInfo',
            'isMobile',
            'currentLocale',
            'currentSportType'
        ]),
    },
}
</script>

<style>
  .player__transfer-table__header{
      display: flex;
      background-color:transparent;
      padding: 1rem;
      border-bottom: .1rem solid #212B30;
  }
  .player__transfer-table__header-child{
        flex: 1;
        font-size: .8rem;
        text-align: center;
        font-weight: 700;
  }
  .player__transfer-table__body{
        font-size: .85rem;
        display: flex;
        padding: 1rem;
        align-items: center;
        border-bottom: .1rem solid #212B30;
  }
  .player__transfer-table__body-child{
       flex:1;
       display: flex;
       justify-content: center;
       align-items: center;
       text-align: center;
       color: white;
  }
   .player__transfer-table__body-child:hover{
       color: var(--color-hover)
   }
  .player__transfer-table__body-img{
      width: 2rem;
        height: 2rem;
        margin-bottom:.7rem
  }
  .player__transfer-table__body-img img{
      width: 100%;
  }
    @media (max-width: 768px){
        .player__transfer-table__header{
            background-color: var(--color-theme);
        }
        .player__transfer-table__body{
            margin: 0 1rem;
            padding: 1rem 0;
        }
    }

</style>